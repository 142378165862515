import * as React from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";

import "./Footer.css";

interface FooterProps {}

class Footer extends React.Component<FooterProps, {}> {
  render() {
    const cardStyle = {
      width: "18em",
      border: "none",
      background: "#fff",
      color: "#222222"
    };

    return (
      <Container fluid className="footer-bg footer">
        <Row className="justify-content-md-center" xs={1} md={2}>
            <Col md="auto" lg="auto">
                <Card border="light" style={{ ...cardStyle, display: "inline-block" }}>
                    <h5>whitelist.sh <span>APP</span></h5>
                </Card>
            </Col>
            <Col md="auto" lg="auto">
                <Card border="light" style={cardStyle}>
                    <ListGroup variant="flush">
                        <Card.Text>
                            Made by <a target="_blank" rel="noopener noreferrer" href={"https://github.com/thundergolfer"}><strong>@thundergolfer</strong></a> to help you become a great engineer. no bullshit.<br/>no shortcuts.
                        </Card.Text>
                        <Card.Text>
                            Sydney, Australia<br/>
                            © 2020 Jonathon Belotti
                        </Card.Text>
                    </ListGroup>
                </Card>
            </Col>
        </Row>
      </Container>
    );
  }
}

export default Footer;
