import 'bootstrap/dist/css/bootstrap.min.css';

import * as React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";

import { colors } from '../styles/variables'

import Page from '../components/Page'
import Post from '../components/Post'
import Container from '../components/Container'
import IndexLayout from '../layouts'

interface PageTemplateProps {
  data: {
    site: {
      siteMetadata: {
        title: string
        description: string
        author: {
          name: string
          url: string
        }
      }
    }
    markdownRemark: {
      html: string
      excerpt: string
      frontmatter: {
        title: string
        category: string
        date: string
      }
    }
  }
}

const StyledH2 = styled.h2`
  text-align: center;
  font-weight: 400;
  color: ${colors.text};
`;

const CategorySpan: React.FunctionComponent<{category: string}> = (props) => {
  let color;
  switch(props.category) {
    case "product":
      color = colors.whitelistDarkBlue;
      break;
    case "discussion":
      color = colors.whitelistGreen;
      break;
    case "engineering":
      color = colors.whitelistRed;
      break;
    default:
      color = "black";
  }

  return (
    <span style={{color}}>
      {props.category.charAt(0).toUpperCase() + props.category.slice(1)}
    </span>
  );
};

const CallToAction: React.FunctionComponent<{category: string}> = (props) => {
  let cardStyle = {
    backgroundColor: colors.ui.light,
    maxWidth: "600px",
    fontWeight: 200,
    fontSize: "0.9em",
    marginTop: "50px",
  };

  switch(props.category) {
    case "product":
      return (
        <Card style={cardStyle} className="justify-content-md-center">
          <Card.Body>
            <Row style={{alignItems: "center"}}>
              <Col>
                <Card.Text>
                  Sound good to you?
                </Card.Text>
              </Col>
              <Col>
                <Button variant="primary" href="https://whitelist.sh">Try Whitelist.sh</Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      );
    case "discussion":
      return (
        <Card style={cardStyle} className="justify-content-md-center">
          <Card.Body>
            <Row style={{alignItems: "center"}}>
              <Col>
                <Card.Text>
                  Do our ideas and values resonate with you?
                </Card.Text>
              </Col>
              <Col>
                <Button variant="primary" href="https://whitelist.sh">Try Whitelist.sh</Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      );
    default:
      return null;
  }
}

const PageTemplate: React.FunctionComponent<PageTemplateProps> = ({ data }) => (
  <IndexLayout>
    <Page>
      <Post>
        <Container>
          <StyledH2>{data.markdownRemark.frontmatter.title}</StyledH2>
          <Row className="justify-content-md-center" style={{marginBottom: "50px"}}>
            <Col
              md="auto"
              sm={12}
              xs={12}
              style={{textAlign: "center"}}
            >
              Published on {data.markdownRemark.frontmatter.date} — <CategorySpan category={data.markdownRemark.frontmatter.category} />
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col style={{maxWidth: "650px"}}>
              <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
            </Col>
          </Row>
          <Row className="justify-content-md-center" style={{marginBottom: "50px", justifyContent: "center"}}>
            <CallToAction category={data.markdownRemark.frontmatter.category} />
          </Row>
        </Container>
      </Post>
    </Page>
  </IndexLayout>
);

export default PageTemplate

export const query = graphql`
  query PageTemplateQuery($slug: String!) {
    site {
      siteMetadata {
        title
        description
        author {
          name
          url
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        title
        category
        date
      }
    }
  }
`
