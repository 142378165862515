import * as React from 'react'
import styled from '@emotion/styled'

import { colors, dimensions } from '../styles/variables'

const StyledPost = styled.div`
  display: block;
  flex: 1;
  position: relative;
  padding: ${dimensions.containerPadding}rem;
  margin-bottom: 3rem;
  background-color: ${colors.white};
  margin: 50px auto 100px;
  border: 1px solid #eaecee;
  color: ${colors.text};
  max-width: 850px;
  height: auto;
  border-radius: 5px 5px;
`;

interface PageProps {
  className?: string
}

const Post: React.SFC<PageProps> = ({ children, className }) => <StyledPost className={className}>
  {children}
</StyledPost>;

export default Post;
