/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Link } from "gatsby"

import Card from "react-bootstrap/Card";

const cardStyle = {
  width: "50rem",
  padding: "1rem",
  margin: "2rem",
  border: "none",
  background: "#fff",
  color: "#222222",
  display: "inline-block",
  backgroundImage: "linear-gradient(rgb(255, 255, 255), rgb(245, 245, 250))",
  boxShadow: "rgba(93, 100, 148, 0.2) 0px 4px 34px 0px",
  transition: "transform 0.2s ease-out 0s, box-shadow 0.2s ease-out 0s",
  ":hover": {
    transform: "scale(1.03)",
  }
};

const titleStyle = {
  color: "#222222",
}

const linkStyle = {
  color: "#222222",
}

// @ts-ignore
const PostLink = ({ post }) => (
  <Card css={cardStyle}>
    <Card.Title style={titleStyle}>
      <Link to={post.frontmatter.path} style={linkStyle}>
        {post.frontmatter.title}
      </Link>
    </Card.Title>
    <Card.Text>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      Maecenas non arcu sed metus ornare laoreet et pulvinar augue.
      Vivamus blandit sapien sit amet augue facilisis hendrerit. Phasellus rutrum vitae risus vitae lacinia.
    </Card.Text>
    <Card.Footer>
      ({post.frontmatter.date})
    </Card.Footer>
  </Card>
);

export default PostLink;
