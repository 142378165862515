/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import * as React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

import { fonts } from '../styles/variables'

// import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

import { heights, dimensions, colors } from '../styles/variables'
import Container from './Container'

const StyledHeader = styled.header`
  height: ${heights.header}px;
  padding: 0 ${dimensions.containerPadding}rem;
  background-color: ${colors.backgroundGray};
  color: black;
`;

const HeaderInner = styled(Container)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
`;

/*
 * Attempts to replicate the company title style for the Algolia blog.
 * See: https://blog.algolia.com/ (top of page, or footer)
 */
const StyledBlogTag = styled.span`
  background-color: #1b1e21;
  font-family: ${fonts.sansSerif};
  color: #ffffff;
  padding: 2%;
  border-radius: 10%;
  font-size: 0.75em;
  width: 80%;
`;

interface HeaderProps {
  title: string
}

const hamburgerMenuDropdownStyle = css`
  @media (max-width: 600px) {
    background-color: white;
    width: 100%;
    padding: 10%;
  }
`;

const Header: React.FunctionComponent<HeaderProps> = ({}) => (
  <StyledHeader>
    <Navbar
      className="navbar"
      sticky="top"
      style={{margin: "0 10vw"}}
      expand="lg"
    >
      <Navbar.Brand href="/">
        <img src="https://whitelist-assets.s3.amazonaws.com/0.75x/whitelist-logo-dark-ldpi.png" height={50} width={50} />
        whitelist.sh <StyledBlogTag>BLOG</StyledBlogTag>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse css={hamburgerMenuDropdownStyle}>
        <Nav className="ml-auto">
          <Nav.Link href="https://whitelist.sh">Whitelist.sh App</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </StyledHeader>
);

export default Header
