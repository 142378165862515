import * as React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import Page from '../components/Page'
import Container from '../components/Container'
import PostLink from "../components/PostLink"
import IndexLayout from '../layouts'

// @ts-ignore
const Posts = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)
  return <div style={{textAlign: "center"}}>{Posts}</div>
};

const IndexPage = () => (
  <IndexLayout>
    <Page>
      <Container>
      </Container>
      <StaticQuery
        query={graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
          }
        }
      }
    }
  }
`}
        render={data => (
          <Posts data={data} />
        )}
        />
    </Page>
  </IndexLayout>
);

export default IndexPage
